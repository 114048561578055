import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Logo, PlayButton } from "../assets";

const Header = () => {
  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="absolute w-full px-2 sm:px-12 xl:px-16 2xl:px-32 bg-transparent text-white">
            <div className="flex lg:h-32 h-24 items-center justify-between ">
              <div className="flex flex-1 items-start justify-start md:items-stretch md:justify-start">
                <div className="flex flex-shrink-0 items-start">
                  <div className="flex flex-row">
                    <div className="flex">
                      <a href="/">
                        <img
                          className="lg:h-32 h-24 w-full"
                          src={Logo}
                          alt="Jayamaga Logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                <div className="hidden md:ml-6 md:block">
                  <div className="flex lg:space-x-4 flex-row lg:gap-10 gap-2">
                    <div className="flex mt-2">
                      <a
                        href="/"
                        className="flex font-nunito text-[#F85A76] bg-[#EBEBEB] border-4 border-white rounded-3xl w-full h-10 justify-center items-center px-4 py-[1px] text-base font-bold shadow-lg"
                      >
                        HOME
                      </a>
                    </div>
                    <div className="flex mt-2">
                      <a
                        href="/#about"
                        className="flex font-nunito text-[#F85A76] bg-[#EBEBEB] border-4 border-white rounded-3xl w-full h-10 justify-center items-center px-4 py-[1px] text-base font-bold shadow-lg"
                      >
                        ABOUT US
                      </a>
                    </div>
                    <div className="flex mt-2">
                      <a
                        href="/#contact"
                        className="flex font-nunito text-[#F85A76] bg-[#EBEBEB] border-4 border-white rounded-3xl w-full h-10 justify-center items-center px-4 py-[1px] text-base font-bold shadow-lg"
                      >
                        CONTACT US
                      </a>
                    </div>
                    <div className="flex mt-2">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={PlayButton}
                          alt="playstore"
                          className="h-12"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute right-0 md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-end justify-end rounded-md p-2  text-[#BA9449] hover:text-[#96BC20] focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="flex flex-col gap-3 pb-2 px-4 sm:px-16 justify-end content-end items-end">
              <Disclosure.Button
                href="/"
                className="block mt-20 text-base font-bold relative w-fit after:items-end after:justify-end after:content-[''] after:bg-teal after:absolute after:h-[3px] after:w-[0%] after:left-0 after:bottom-0 after:rounded-xl after:duration-300 hover:after:w-full"
              >
                Home
              </Disclosure.Button>
              <Disclosure.Button
                href="/#about"
                className="block text-base font-bold relative w-fit after:items-end after:justify-end after:content-[''] after:bg-teal after:absolute after:h-[3px] after:w-[0%] after:left-0 after:bottom-0 after:rounded-xl after:duration-300 hover:after:w-full"
              >
                About Us
              </Disclosure.Button>
              <Disclosure.Button
                href="/#contact"
                className="block text-base font-bold relative w-fit after:items-end after:justify-end after:content-[''] after:bg-teal after:absolute after:h-[3px] after:w-[0%] after:left-0 after:bottom-0 after:rounded-xl after:duration-300 hover:after:w-full"
              >
                Contact Us
              </Disclosure.Button>
              <div className="flex">
                <a
                  href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={PlayButton} alt="playstore" className="h-12" />
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default Header;
