import React from "react";
import Header from "./components/Header";
import { MobHeader, WebHeader } from "./assets";
import Footer from "./components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="bg-[#FFEDEF] w-full h-auto relative">
      <div className="absolute w-full top-0">
        <img
          src={WebHeader}
          alt="back"
          className="w-full md:visible max-md:hidden"
        />
        <img src={MobHeader} alt="back" className="w-full md:hidden" />
      </div>
      <Header />
      <div className="flex flex-col pt-60 w-full px-4 sm:px-12 xl:px-16 2xl:px-32 pb-12">
        <div className="flex font-bold lg:text-4xl md:text-2xl text-lg text-black">
          Privacy Policy
        </div>
        <div className="flex font-normal lg:text-2xl md:text-xl text-md text-black mt-4">
          Thank you for clarifying the data that you collect. Based on your
          comment, here is an updated Privacy Policy description:
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Data Collection:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          We only collect your mobile number when you use the 5 Wasara Jayamaga
          app. This information is required to provide you with personalized
          educational content and to track your progress.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Why we collect data:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          We collect your mobile number to personalize your educational
          experience and provide you with relevant educational content. We use
          this information to track your progress and provide you with feedback
          on your performance.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Data Protection:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          We are committed to ensuring the safety and security of your data. We
          use industry-standard encryption methods and secure storage techniques
          to protect your mobile number from unauthorized access, disclosure, or
          alteration.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Data Retention:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          We retain your mobile number only for the duration of your active use
          of the app.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Data Deletion:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          User Initiated Deletion: You have the right to request the immediate
          deletion of your mobile number and associated data at any time by
          accessing the "Delete Account" option within the app's navigation
          drawer. Upon confirming your decision, we will promptly delete all
          your data from our systems.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          No Data Retention Post-Deletion: We do not retain any data after you
          request deletion.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Third-Party Services:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          We do not use any third-party services to collect or store your mobile
          number.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          Updates to this Privacy Policy:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          We may update this Privacy Policy from time to time to reflect changes
          in the app’s functionality or legal requirements. We will notify you
          of any significant changes to this policy via email or through the
          app.
        </div>
        <div className="flex font-normal lg:text-2xl md:text-xl text-md text-black mt-4">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at info@jayamaga.lk.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
