import React, { useRef, useState } from "react";
import { ContactRight } from "../assets";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_h1w9rf1",
        "template_ls4ud9c",
        {
          from_name: form.name,
          to_name: "Aventure IT",
          from_email: form.email,
          to_email: "aknpremakumara@gmail.com",
          message: form.message,
        },
        "yJiNchYbBt5cjTChT"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you!. We will get back to you as soon as possible.");
          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
          alert("Something went wrong.");
        }
      );
  };

  return (
    <div
      id="contact"
      className="flex bg-[#FFF4CB] bg-contact-back xl:flex-row flex-col w-full h-auto px-4 sm:px-12 xl:px-16 2xl:px-32 xl:pt-32 pt-12 pb-12"
    >
      <div className="flex flex-col items-center w-full">
        <div className="flex font-nunito lg:text-2xl md:text-xl text-lg font-bold text-center">
          CONTACT US
        </div>
        <div className="flex font-nunito lg:text-2xl md:text-xl text-lg font-bold text-[#E57070] text-center mt-2">
          Love to hearing from you, Get in touch
        </div>
        <div className="flex flex-col w-[70%] p-6 pt-6 rounded-lg shadow-md bg-[#E7E7E7] mt-12">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex md:flex-row flex-col bg-white px-4 py-4 rounded-md focus:border-none w-full mt-8">
              <label className="flex md:w-[30%] w-full">Your Name :</label>
              <input
                type="text"
                name="name"
                className="md:ml-4 w-full px-2 md:mx-2"
                value={form.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex md:flex-row flex-col bg-white px-4 py-4 rounded-md focus:border-none w-full mt-4">
              <label className="flex md:w-[30%] w-full">Your Email :</label>
              <input
                type="email"
                name="email"
                className="md:ml-4 w-full px-2 md:mx-2"
                value={form.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex md:flex-row flex-col bg-white px-4 py-4 rounded-md focus:border-none w-full mt-4">
              <label className="flex md:w-[30%] w-full">Message :</label>
              <textarea
                type="text"
                name="message"
                className="md:ml-4 w-full px-2 md:mx-2"
                value={form.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex w-full justify-center items-center">
              <button
                type="submit"
                className="flex bg-[#E57070] md:w-[50%] w-full px-8 py-2 mt-8 rounded-lg justify-center text-[32px] text-white font-nunito font-bold text-center"
              >
                {loading ? "SENDING..." : "SEND"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex w-full h-full p-4 justify-center items-center">
        <img src={ContactRight} alt="ilustrator" />
      </div>
    </div>
  );
};

export default Contact;
