import React from "react";
import { MobHeader, WebHeader } from "./assets";
import Header from "./components/Header";
import Footer from "./components/Footer";

const TermsConditions = () => {
  return (
    <div className="bg-[#FFEDEF] w-full h-auto relative">
      <div className="absolute w-full top-0">
        <img
          src={WebHeader}
          alt="back"
          className="w-full md:visible max-md:hidden"
        />
        <img src={MobHeader} alt="back" className="w-full md:hidden" />
      </div>
      <Header />
      <div className="flex flex-col pt-60 w-full px-4 sm:px-12 xl:px-16 2xl:px-32 pb-12">
        <div className="flex font-bold lg:text-4xl md:text-2xl text-lg text-black">
          Terms and Conditions
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          01. Registration and Eligibility:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. The 5 Wasara Jayamaga Mobile app is exclusively designed for grade
          3, 4, 5 students in Sri Lanka who are preparing for the grade 5
          scholarship exam.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. To access the 5 Wasara Jayamaga mobile app, users must register
          using a valid mobile phone number from Dialog, Airtel, or Hutch.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          02. Subscription Fee:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. Users will be charged a daily subscription fee of Rs: 6 + tax.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. The subscription fee will be daily deducted from the user's mobile
          balance.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          c. If users do not have sufficient balance on their mobile phone
          number, they must recharge or provide an alternative valid number in
          order to gain access.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          03. User Responsibilities:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. Users are responsible for maintaining the confidentiality of their
          account information and login credentials.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. Users must not share their Login details & OTPs with others or
          allow unauthorized access to the app.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          04. App Usage:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. The 5 wasara Jayamaga mobile app is intended solely for grade 5
          scholarship exam preparation.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. Users may access the app's study materials, practice tests, and
          related content.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          05. Intellectual Property:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. Users may not reproduce, distribute, modify, or create derivative
          works based on the app's content without explicit permission.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          06. Termination & Unsubscribe:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. The5 wasara jayamaga app reserves the right to terminate user
          accounts for violations of the terms and conditions or misuse of the
          app.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. Users may also terminate or Unsubscribe their subscription at any
          time by contacting app support or in App settings.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          07. Privacy and Data Collection:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. The app collects and stores personal data, such as mobile phone
          numbers, for registration and subscription purposes.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. User data will be treated in accordance with applicable privacy
          laws and regulations.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          08. Disclaimer of Liability:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. Users acknowledge that the app is not a substitute for classroom
          instruction and should be used as an additional educational resource.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          09. Modifications to Terms and Conditions:
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          a. The 5 wasara jayamaga app reserves the right to modify or update
          these terms and conditions at any time.
        </div>
        <div className="flex font-light lg:text-2xl md:text-xl text-md text-black mt-2">
          b. Users will be notified of any changes, and continued app usage
          constitutes acceptance of the modified terms.
        </div>
        <div className="flex font-medium lg:text-2xl md:text-xl text-md text-black mt-4">
          10. If you have any questions or suggestions about our 5 wasara
          jayamaga mobile app, do not hesitate to contact us at info@jayamaga.lk
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
