import React, { useState } from "react";
import Header from "./Header";
import {
  Essays,
  HeaderMain,
  MobFooter,
  MobHeader,
  ModelPapers,
  PastPapers,
  PlayButton,
  School,
  WebFooter,
  WebHeader,
} from "../assets";
import { Modal } from "antd";
import { CgFacebook } from "react-icons/cg";

const Home = () => {
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isModal3Open, setIsModal3Open] = useState(false);
  const [isModal4Open, setIsModal4Open] = useState(false);

  const showModal1 = () => {
    setIsModal1Open(true);
  };
  const showModal2 = () => {
    setIsModal2Open(true);
  };
  const showModal3 = () => {
    setIsModal3Open(true);
  };
  const showModal4 = () => {
    setIsModal4Open(true);
  };

  const handleOk1 = () => {
    setIsModal1Open(false);
  };
  const handleOk2 = () => {
    setIsModal2Open(false);
  };
  const handleOk3 = () => {
    setIsModal3Open(false);
  };
  const handleOk4 = () => {
    setIsModal4Open(false);
  };

  const handleCancel1 = () => {
    setIsModal1Open(false);
  };
  const handleCancel2 = () => {
    setIsModal2Open(false);
  };
  const handleCancel3 = () => {
    setIsModal3Open(false);
  };
  const handleCancel4 = () => {
    setIsModal4Open(false);
  };

  return (
    <div className="bg-[#FFEDEF] w-full h-auto relative">
      <div className="absolute w-full top-0">
        <img
          src={WebHeader}
          alt="back"
          className="w-full md:visible max-md:hidden"
        />
        <img src={MobHeader} alt="back" className="w-full md:hidden" />
      </div>
      <Header />
      <div className="flex lg:flex-row flex-col sm:pt-60 pt-52 w-full px-4 justify-center items-center sm:px-12 xl:px-16 2xl:px-32">
        <div className="flex flex-col justify-center items-center xl:-mt-32 -mt-24 z-30">
          <div className="flex text-black xl:text-[28px] lg:text-[24px] md:text-[20px] text-[16px] text-center font-bold font-amsipro radientText">
            ශිෂ්‍යත්ව විභාගය වෙනුවෙන්ම සැකසූ විෂය
          </div>
          <div className="flex text-black xl:text-[28px] lg:text-[24px] md:text-[20px] text-[16px] text-center font-bold font-amsipro radientText">
            කරුණු එකතුව, රචනා සහ විභාග හුරුව
          </div>
          <div className="flex flex-row mt-4 w-full justify-center items-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
              target="_blank"
              rel="noreferrer"
            >
              <img src={PlayButton} alt="playstore" className="h-16" />
            </a>
            <a
              href="https://web.facebook.com/5wasarajayamaga"
              target="_blank"
              rel="noreferrer"
              className="flex font-nunito mb-2 text-blue-600 bg-[#EBEBEB] border-4 border-white rounded-3xl h-12 justify-center items-center px-6 py-2 text-xl font-bold shadow-lg"
            >
              <CgFacebook />
              Facebook
            </a>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex sm:flex-row flex-col w-full">
              <div
                className="flex flex-col justify-center items-center bg-[#F3D223] border-[3px] border-white rounded-3xl p-4 sm:m-2 sm:w-1/2 w-full cursor-pointer"
                onClick={showModal1}
              >
                <img src={ModelPapers} alt="modelpapers" />
                <label className="flex text-[24px] font-bold font-amsipro borderText justify-center text-center">
                  අනුමාන ප්‍රශ්නපත්‍ර
                </label>
              </div>
              <div
                className="flex flex-col justify-center items-center bg-[#92EB3A] border-[3px] border-white rounded-3xl p-4 sm:m-2 sm:w-1/2 w-full cursor-pointer"
                onClick={showModal2}
              >
                <img src={PastPapers} alt="modelpapers" />
                <label className="flex text-[24px] font-bold font-amsipro borderText justify-center text-center">
                  පසුගිය ප්‍රශ්නපත්‍ර
                </label>
              </div>
            </div>
            <div className="flex sm:flex-row flex-col w-full">
              <div
                className="flex flex-col justify-center items-center bg-[#FF8787] border-[3px] border-white rounded-3xl p-4 sm:m-2 sm:w-1/2 w-full cursor-pointer"
                onClick={showModal3}
              >
                <img src={Essays} alt="modelpapers" />
                <label className="flex text-[24px] font-bold font-amsipro borderText justify-center text-center">
                  රචනා හුරුව
                </label>
              </div>
              <div
                className="flex flex-col justify-center items-center bg-[#60DBC5] border-[3px] border-white rounded-3xl p-4 sm:m-2 sm:w-1/2 w-full cursor-pointer"
                onClick={showModal4}
              >
                <img src={School} alt="modelpapers" />
                <label className="flex text-[24px] font-bold font-amsipro borderText justify-center text-center">
                  අපේ පාසල
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:-mt-32 z-30">
          <img src={HeaderMain} alt="header" />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col w-full px-4 justify-center items-center sm:px-12 xl:px-16 2xl:px-32 pb-12">
        <div className="flex bg-white rounded-xl 2xl:text-[24px] lg:text-[20px] md:text-[18px] text-[16px] text-center font-semibold text-[#C85454] z-40 px-4 py-1">
          නව ලොව දිනන්නට 5 වසර පොඩ්ඩන්ට නිසැක ජය
        </div>
      </div>
      <div className="absolute w-full bottom-0">
        <img
          src={WebFooter}
          alt="back"
          className="w-full md:visible max-md:hidden"
        />
        <img src={MobFooter} alt="back" className="w-full md:hidden" />
      </div>
      <Modal
        open={isModal1Open}
        onOk={handleOk1}
        onCancel={handleCancel1}
        className="bgcolor1"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={1000}
      >
        <div className="flex lg:flex-row flex-col">
          <div className="flex flex-col lg:w-[30%] w-full max-lg:justify-center max-lg:items-center">
            <div className="border-[2px] border-white rounded-md shadow-lg bg-[#F3D223] p-6">
              <img
                src={ModelPapers}
                alt="Our School"
                className="flex lg:w-full h-full w-72"
              />
            </div>
            <div className="flex mt-4">
              <button
                className="flex bg-white px-8 py-1 rounded-2xl text-[#AA9C6B]"
                onClick={handleCancel1}
              >
                Back
              </button>
            </div>
          </div>
          <div className="w-8"></div>
          <div className="flex flex-col lg:w-[60%] w-full max-lg:justify-center max-lg:text-center max-lg:items-center max-lg:mt-4">
            <div className="flex font-bold text-[24px] borderTitle">
              අනුමාන විභාග ප්‍රශ්නපත්‍ර
            </div>
            <div className="flex font-semibold text-[16px] text-black">
              දැනුම, විනෝදය සමඟින් නව විෂය නිර්දේශයට අනුකූලව විධිමත්ව සැකසූ 3,
              4, 5 ශ්‍රේණි සඳහා වන විෂය කරුණු එකතුව හා විභාග පෙරහුරු දුරකථන
              යෙදවුම. ඔබේ දරුවාත් 5 ශ්‍රේණිය ශිෂ්‍යත්ව විභාගයට පෙනී සිටීමට
              සූදානම් ද? සාම්ප්‍රදායික අධ්‍යාපන රටාවෙන් බැහැරව, නවමු තාක්ෂණය
              ඔස්සේ සිත්ගන්නාසුළු, උද්යෝගිමත් අධ්‍යාපන අත්දැකීමක් සමගින් ඔබේ
              දරුවාත් නිසැක ජයක් ලබාගැනීමට සැකසූ ශිෂ්‍යත්ව ජයමග.
            </div>
            <div className="text-[#A67803] text-base font-semibold w-full text-center mt-4">
              5 වසර ජයමග දුරකථන යෙදවුම ලබාගන්න
            </div>
            <div className="flex w-full justify-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PlayButton} alt="playstore" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isModal2Open}
        onOk={handleOk2}
        onCancel={handleCancel2}
        className="bgcolor2"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={1000}
      >
        <div className="flex lg:flex-row flex-col">
          <div className="flex flex-col lg:w-[30%] w-full max-lg:justify-center max-lg:items-center">
            <div className="border-[2px] border-white rounded-md shadow-lg bg-[#92EB3A] p-6">
              <img
                src={PastPapers}
                alt="Our School"
                className="flex lg:w-full h-full w-72"
              />
            </div>
            <div className="flex mt-4">
              <button
                className="flex bg-white px-8 py-1 rounded-2xl text-[#9DAA6B]"
                onClick={handleCancel2}
              >
                Back
              </button>
            </div>
          </div>
          <div className="w-8"></div>
          <div className="flex flex-col lg:w-[60%] w-full max-lg:justify-center max-lg:text-center max-lg:items-center max-lg:mt-4">
            <div className="flex font-bold text-[24px] borderTitle">
              පසුගිය විභාග ප්‍රශ්නපත්‍ර
            </div>
            <div className="flex font-semibold text-[16px] text-black">
              දැනුම, විනෝදය සමඟින් නව විෂය නිර්දේශයට අනුකූලව විධිමත්ව සැකසූ 3,
              4, 5 ශ්‍රේණි සඳහා වන විෂය කරුණු එකතුව හා විභාග පෙරහුරු දුරකථන
              යෙදවුම. ඔබේ දරුවාත් 5 ශ්‍රේණිය ශිෂ්‍යත්ව විභාගයට පෙනී සිටීමට
              සූදානම් ද? සාම්ප්‍රදායික අධ්‍යාපන රටාවෙන් බැහැරව, නවමු තාක්ෂණය
              ඔස්සේ සිත්ගන්නාසුළු, උද්යෝගිමත් අධ්‍යාපන අත්දැකීමක් සමගින් ඔබේ
              දරුවාත් නිසැක ජයක් ලබාගැනීමට සැකසූ ශිෂ්‍යත්ව ජයමග.
            </div>
            <div className="text-[#72A603] text-base font-semibold w-full text-center mt-4">
              5 වසර ජයමග දුරකථන යෙදවුම ලබාගන්න
            </div>
            <div className="flex w-full justify-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PlayButton} alt="playstore" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isModal3Open}
        onOk={handleOk3}
        onCancel={handleCancel3}
        className="bgcolor3"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={1000}
      >
        <div className="flex lg:flex-row flex-col">
          <div className="flex flex-col lg:w-[30%] w-full max-lg:justify-center max-lg:items-center">
            <div className="border-[2px] border-white rounded-md shadow-lg bg-[#FF8787] p-6">
              <img
                src={Essays}
                alt="Our School"
                className="flex lg:w-full h-full w-72"
              />
            </div>
            <div className="flex mt-4">
              <button
                className="flex bg-white px-8 py-1 rounded-2xl text-[#9E7C71]"
                onClick={handleCancel3}
              >
                Back
              </button>
            </div>
          </div>
          <div className="w-8"></div>
          <div className="flex flex-col lg:w-[60%] w-full max-lg:justify-center max-lg:text-center max-lg:items-center max-lg:mt-4">
            <div className="flex font-bold text-[24px] borderTitle">
              රචනා හුරුව
            </div>
            <div className="flex font-semibold text-[16px] text-black">
              දැනුම, විනෝදය සමඟින් නව විෂය නිර්දේශයට අනුකූලව විධිමත්ව සැකසූ 3,
              4, 5 ශ්‍රේණි සඳහා වන විෂය කරුණු එකතුව හා විභාග පෙරහුරු දුරකථන
              යෙදවුම. ඔබේ දරුවාත් 5 ශ්‍රේණිය ශිෂ්‍යත්ව විභාගයට පෙනී සිටීමට
              සූදානම් ද? සාම්ප්‍රදායික අධ්‍යාපන රටාවෙන් බැහැරව, නවමු තාක්ෂණය
              ඔස්සේ සිත්ගන්නාසුළු, උද්යෝගිමත් අධ්‍යාපන අත්දැකීමක් සමගින් ඔබේ
              දරුවාත් නිසැක ජයක් ලබාගැනීමට සැකසූ ශිෂ්‍යත්ව ජයමග.
            </div>
            <div className="text-[#A62A03] text-base font-semibold w-full text-center mt-4">
              5 වසර ජයමග දුරකථන යෙදවුම ලබාගන්න
            </div>
            <div className="flex w-full justify-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PlayButton} alt="playstore" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isModal4Open}
        onOk={handleOk4}
        onCancel={handleCancel4}
        className="bgcolor4"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={1000}
      >
        <div className="flex lg:flex-row flex-col">
          <div className="flex flex-col lg:w-[30%] w-full max-lg:justify-center max-lg:items-center">
            <div className="border-[2px] border-white rounded-md shadow-lg bg-[#60DBC5] p-6">
              <img
                src={School}
                alt="Our School"
                className="flex lg:w-full h-full w-72"
              />
            </div>
            <div className="flex mt-4">
              <button
                className="flex bg-white px-8 py-1 rounded-2xl text-[#739E99]"
                onClick={handleCancel4}
              >
                Back
              </button>
            </div>
          </div>
          <div className="w-8"></div>
          <div className="flex flex-col lg:w-[60%] w-full max-lg:justify-center max-lg:text-center max-lg:items-center max-lg:mt-4">
            <div className="flex font-bold text-[24px] borderTitle">
              අපේ පාසල
            </div>
            <div className="flex font-semibold text-[16px] text-black">
              දැනුම, විනෝදය සමඟින් නව විෂය නිර්දේශයට අනුකූලව විධිමත්ව සැකසූ 3,
              4, 5 ශ්‍රේණි සඳහා වන විෂය කරුණු එකතුව හා විභාග පෙරහුරු දුරකථන
              යෙදවුම. ඔබේ දරුවාත් 5 ශ්‍රේණිය ශිෂ්‍යත්ව විභාගයට පෙනී සිටීමට
              සූදානම් ද? සාම්ප්‍රදායික අධ්‍යාපන රටාවෙන් බැහැරව, නවමු තාක්ෂණය
              ඔස්සේ සිත්ගන්නාසුළු, උද්යෝගිමත් අධ්‍යාපන අත්දැකීමක් සමගින් ඔබේ
              දරුවාත් නිසැක ජයක් ලබාගැනීමට සැකසූ ශිෂ්‍යත්ව ජයමග.
            </div>
            <div className="text-[#1A9292] text-base font-semibold w-full text-center mt-4">
              5 වසර ජයමග දුරකථන යෙදවුම ලබාගන්න
            </div>
            <div className="flex w-full justify-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PlayButton} alt="playstore" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
