import React from "react";
import { Elips, Logo, PlayButton, Screenshots } from "../assets";

const About = () => {
  return (
    <div id="about" className="flex bg-[#FFF4CB] xl:flex-row flex-col w-full h-auto px-4 sm:px-12 xl:px-16 2xl:px-32 xl:pt-32 pt-12 pb-12">
      <div className="flex flex-col relative w-full justify-center items-center">
        <img src={Screenshots} alt="screenshots" className="z-10 w-[600px]" />
        <img
          src={Elips}
          alt="animation"
          className="absolute max-sm:w-64 w-[90%] z-0 animate-spin-slow"
        />
        <div className="flex w-full justify-center z-20 mt-8">
          <a
            href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
            target="_blank"
            rel="noreferrer"
          >
            <img src={PlayButton} alt="playstore" className="h-16" />
          </a>
        </div>
      </div>
      <div className="flex flex-col w-full xl:-mt-20 mt-12 z-10">
        <div className="flex w-full justify-center items-center">
          <img src={Logo} alt="logo" />
        </div>
        <div className="flex font-semibold font-amsipro lg:text-lg md:text-base text-sm">
          දැනුම, විනෝදය සමඟින් නව විෂය නිර්දේශයට අනුකූලව විධිමත්ව සැකසූ 3, 4, 5
          ශ්‍රේණි සඳහා වන විෂය කරුණු එකතුව හා විභාග පෙරහුරු දුරකථන යෙදවුම. ඔබේ
          දරුවාත් 5 ශ්‍රේණිය ශිෂ්‍යත්ව විභාගයට පෙනී සිටීමට සූදානම් ද?
          සාම්ප්‍රදායික අධ්‍යාපන රටාවෙන් බැහැරව, නවමු තාක්ෂණය ඔස්සේ
          සිත්ගන්නාසුළු, උද්යෝගිමත් අධ්‍යාපන අත්දැකීමක් සමගින් ඔබේ දරුවාත් නිසැක
          ජයක් ලබාගැනීමට සැකසූ ශිෂ්‍යත්ව ජයමග.
          <br />
          <br />
          1. පළපුරුදු වෘත්තීමය පාසල් 3, 4, 5 ශ්‍රේණි ආචාර්ය මණ්ඩලයක් විසින්
          අන්තර්ගතය සැකසීම හා අධීක්ෂණය සිදු කරයි.
          <br />
          2. වයස 8 හා 10 අතර දරුවන්ට භාවිතයට පහසු හා සරල අයුරින් දුරකථන යෙදවුම
          නිර්මාණය කර ඇත.
          <br />
          3. සාම්ප්‍රධායික අධ්‍යාපන රටාවට අඩු උනන්දුවක් දක්වන දරුවන් සඳහා ඉතා
          ආකර්ෂණීය ලෙස සියළුම විෂය කරුණු විධිමත්ව විෂයානුකූලව පෙළගස්වා ඇත.
          <br />
          4. සෑම සතියකම නව රචනා, විෂය කරුණු හා ප්‍රශ්න පත්‍ර එකතු කිරීම සිදු
          කරයි.
          <br />
          5. ප්‍රවීණ ගුරු මණ්ඩලයක් විසින් නව විෂය නිර්දේශ සඳහා සැකසූ රචනා විශාල
          ප්‍රමාණයක් ඇතුළත්ය(සතිපතා යාවත්කාලීන වේ).
          <br />
          6. අනුමාන ප්‍රශ්න පත්‍ර සහ වසර 10 ක පසුගිය විභාග ප්‍රශ්න පත්‍ර ඇතුලත්
          වේ.
          <br />
          7. සැබෑ විභාගයට සමාන ලෙස පිළිතුරු සැපයීම, කාල කළමනාකරණය හා ලකුණු
          ප්‍රමාණය මැන බැලිය හැකි අයුරින් ප්‍රශ්න පත්‍ර පෙලගස්වා ඇත.
          <br />
          8. 5 වසර ජයමග යෙදවුම තුලින් ඔබේ දරුවාගේ දක්ෂතා හා දුර්වලතා හඳුනාගෙන
          අවශ්‍ය විෂය වැඩිදුර ප්‍රගුණ කිරීමට අවශ්‍ය මග පෙන්වීම සිදු කරයි.
          <br />
          <br />
          සියළුම දරුවන්ගේ දක්ෂතා හා විෂය දැනුම වැඩි දියුණු කර 5 වසර ශිෂ්‍යත්ව
          විභාග කඩඉම සාර්ථකව ලෙස ජය ගැනීමට ජයමගක් වීම අපගේ අරමුණයි.
        </div>
      </div>
    </div>
  );
};

export default About;
