import React from "react";
import {
  Android,
  Apple,
  Facebook,
  Instagram,
  Logo,
  Tiktok,
  Twitter,
} from "../assets";

const Footer = () => {
  return (
    <div className="flex flex-col w-full h-auto px-4 sm:px-12 xl:px-16 2xl:px-32 bg-[#C97878] py-4">
      <div className="flex lg:flex-row flex-col w-full 2xl:text-[24px] lg:text-[20px] md:text-[18px] text-[16px]">
        <div className="flex justify-center items-center lg:w-1/4 px-4">
          <img src={Logo} alt="Jayamaga Logo" />
        </div>
        <div className="flex flex-col lg:w-1/4 px-4">
          <div className="text-white">For more info Contact:</div>
          <a
            href="mailto:info@jayamaga.lk"
            target="_blank"
            rel="noreferrer"
            className="text-white hover:underline cursor-pointer"
          >
            info@jayamaga.lk
          </a>
          <div className="flex flex-row gap-2 mt-4">
            <a
              href="https://web.facebook.com/5wasarajayamaga"
              target="_blank"
              rel="noreferrer"
              className="hover:animate-pulse"
            >
              <img src={Facebook} alt="facebook" className="w-8" />
            </a>
            <a
              href="/"
              target="_blank"
              rel="noreferrer"
              className="hover:animate-pulse"
            >
              <img src={Instagram} alt="instagram" className="w-8" />
            </a>
            <a
              href="/"
              target="_blank"
              rel="noreferrer"
              className="hover:animate-pulse"
            >
              <img src={Twitter} alt="twitter" className="w-8" />
            </a>
            <a
              href="/"
              target="_blank"
              rel="noreferrer"
              className="hover:animate-pulse"
            >
              <img src={Tiktok} alt="tiktok" className="w-8" />
            </a>
          </div>
        </div>
        <hr className="lg:hidden mt-2 border-red-800" />
        <div className="flex flex-col gap-4 text-white lg:w-1/4 px-4 mt-4 lg:mt-0 2xl:text-[24px] lg:text-[20px] md:text-[18px] text-[16px]">
          <a href="/" className="hover:text-red-800">
            Site Map
          </a>
          <a href="/" className="hover:text-red-800">
            Company Info
          </a>
          <a href="/terms" className="hover:text-red-800">
            Terms & Conditions
          </a>
          <a href="/privacy-policy" className="hover:text-red-800">
            Privacy Policy
          </a>
        </div>
        <hr className="lg:hidden mt-2 border-red-800" />
        <div className="flex flex-col gap-4 lg:w-1/4 px-4 mt-4 lg:mt-0 2xl:text-[24px] lg:text-[20px] md:text-[18px] text-[16px]">
          <div className="text-white max-lg:text-center">
            5 Wasara Jayamaga Mobile App:
          </div>
          <div className="text-white max-lg:text-center">
            Available on iTunes & Google Play store.
          </div>
          <div className="flex 2xl:flex-row flex-col gap-3">
            <button className="flex flex-row gap-2 justify-center items-center text-white border-2 border-white px-3 rounded-full py-1">
              <img src={Apple} alt="app store" className="w-6" />
              App Store
            </button>
            <a
              href="https://play.google.com/store/apps/details?id=com.aventureit.jayamaga"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row gap-2 justify-center items-center text-white border-2 border-white px-3 rounded-full py-1"
            >
              <img src={Android} alt="app store" className="w-6" />
              Play Store
            </a>
          </div>
        </div>
      </div>
      <hr className="lg:hidden mt-2 border-red-800" />
      <div className="flex w-full justify-center items-center text-center font-normal text-base mt-4">
        Copyright © 5 Wasara Jayamaga 2023. A Project by Aventure IT
      </div>
    </div>
  );
};

export default Footer;
